const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const ROUTE_LIST_SYSTEM_REQUIREMENT: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_SISTEMA}`;
const ROUTE_LIST_PURCHARSE_REQUIREMENT: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_COMPRA}`;
const ROUTE_LIST_COMPARATIVE: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_COMPARATIVO}`;
const ROUTE_LIST_INVOICE: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_FACTURA}`;
const ROUTE_LIST_PETTY_CASH_REQUEST: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_SOLICITUD_CAJA_CHICA}`;
const ROUTE_LIST_SURRENDER_PETTY_CASH_EXPENSES: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_RENDICION_GASTOS_CAJA_CHICA}`;
const ROUTE_LIST_REQUEST_FORM: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_HOJA_PETICION}`;
const ROUTE_LIST_WORKING_CONDITIONS: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_CONDICION_LABORALES}`;
const ROUTE_LIST_AUSENCIA: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_AUSENCIA}`;
const ROUTE_LIST_DOCUCOBRA: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_DOCUCOBRA}`;
const ROUTE_LIST_VALORIZATION: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_VALORIZACION}`;

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    ROUTE_LIST_COMPARATIVE,
    ROUTE_LIST_INVOICE,
    ROUTE_LIST_SYSTEM_REQUIREMENT,
    ROUTE_LIST_PURCHARSE_REQUIREMENT,
    ROUTE_LIST_PETTY_CASH_REQUEST,
    ROUTE_LIST_SURRENDER_PETTY_CASH_EXPENSES,
    ROUTE_LIST_REQUEST_FORM,
    ROUTE_LIST_WORKING_CONDITIONS,
    ROUTE_LIST_AUSENCIA,
    ROUTE_LIST_DOCUCOBRA,
    ROUTE_LIST_VALORIZATION,
};