import { Suspense } from "react";
import { BrowserRouter, Navigate, Route } from "react-router-dom"
import { AuthGuard } from "./AuthGuard"
import { RoleGuard } from "./RoleGuard";
import { ElementLoader } from "../../../context/shared/Components/ElementLoader"
import { ElementLoaderLazy } from "../../../context/shared/Components/ElementLoaderLazy";
import { RoutesWithNotFound } from "../../../context/shared/Components/RoutesWithNotFound"

//Module Master
import ViewMasterLogin from '../../../context/Master/Login'
import ViewMasterRecuperarPassword from '../../../context/Master/RecuperarPassword'
import ViewMasterMain from '../../../context/Master/Main';
import ViewMasterProfile from '../../../context/Master/Profile';
import ViewMasterVariable from '../../../context/Master/Variable';

//Module Home
import ViewMasterHome from '../../../context/Master/Home';

//Module Approvals
import ViewApprovalsMain from '../../../context/Approvals/Main';
import ViewApprovalsGenericDetail from '../../../context/Approvals/GenericDetail';
import ViewApprovalsGenericList from '../../../context/Approvals/GenericList';
import ViewApprovalsTareoDetail from '../../../context/Approvals/Detail/Tareo';

//Module Docucobra
import ViewDocucobraList from '../../../context/Docucobra/List';
import ViewDocucobraListSecondVersion from '../../../context/Docucobra/ListVersion2';

//Module CajaChica
import ViewCajaChicaMain from '../../../context/PettyCash/Main';
import ViewCajaChicaGenericList from '../../../context/PettyCash/GenericList';
import ViewDetailAnticipo from './../../../context/PettyCash/Detail/Anticipo/Form';
import ViewDetailRendicionGastoForm from './../../../context/PettyCash/Detail/RendicionGasto/Form';
import ViewDetailRendicionGastoAprobacion from './../../../context/PettyCash/Detail/RendicionGasto/Aprobacion';

//Module Tareo
import ViewTareoMain from '../../../context/Tareo/Main';
import ViewTareoGenericList from '../../../context/Tareo/GenericList';
import ViewTareoFormPersonal from '../../../context/Tareo/Detail/Personal';

export const AppRoutes = () => {
    return (
        <Suspense fallback={<ElementLoaderLazy />}>
            <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
                <ElementLoader />
                <RoutesWithNotFound>
                    <Route path="" element={<Navigate to={`/${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_LOGIN}`} element={<AuthGuard element={<ViewMasterLogin />} privateContent={false} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_RECOVERPASSWORD}`} element={<AuthGuard element={<ViewMasterRecuperarPassword />} privateContent={false} />} />
                    <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN}`} element={<AuthGuard element={<ViewMasterMain />} privateContent={true} />}>
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_VARIABLE}`} element={<ViewMasterVariable />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_HOME}`} element={<ViewMasterHome />} />
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`} element={<ViewMasterProfile />} />
                        <Route element={<RoleGuard />}>
                            <Route path={``} element={<ViewMasterHome />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}`}>
                                <Route path={``} element={<ViewApprovalsMain />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_COMPARATIVO}/:id`} element={<ViewApprovalsGenericDetail />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_COMPARATIVO}`} element={<ViewApprovalsGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_FACTURA}/:id`} element={<ViewApprovalsGenericDetail />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_FACTURA}`} element={<ViewApprovalsGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_SISTEMA}/:id`} element={<ViewApprovalsGenericDetail />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_SISTEMA}`} element={<ViewApprovalsGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_COMPRA}/:id`} element={<ViewApprovalsGenericDetail />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_COMPRA}`} element={<ViewApprovalsGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_SOLICITUD_CAJA_CHICA}/:id`} element={<ViewApprovalsGenericDetail />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_SOLICITUD_CAJA_CHICA}`} element={<ViewApprovalsGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_RENDICION_GASTOS_CAJA_CHICA}/:id`} element={<ViewApprovalsGenericDetail />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_RENDICION_GASTOS_CAJA_CHICA}`} element={<ViewApprovalsGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_HOJA_PETICION}/:id`} element={<ViewApprovalsGenericDetail />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_HOJA_PETICION}`} element={<ViewApprovalsGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_CONDICION_LABORALES}/:id`} element={<ViewApprovalsGenericDetail />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_CONDICION_LABORALES}`} element={<ViewApprovalsGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_APROBADOR_GASTO_APROBACION}/:id`} element={<ViewDetailRendicionGastoAprobacion />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_APROBADOR_GASTO_APROBACION}`} element={<ViewCajaChicaGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_TAREO}`} element={<ViewApprovalsGenericList />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_TAREO}/:id`} element={<ViewApprovalsTareoDetail />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_AUSENCIA}`} element={<ViewApprovalsGenericList />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_AUSENCIA}/:id`} element={<ViewApprovalsGenericDetail />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_DOCUCOBRA}`} element={<ViewApprovalsGenericList />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_DOCUCOBRA}/:id`} element={<ViewApprovalsGenericDetail />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_VALORIZACION}`} element={<ViewApprovalsGenericList />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_VALORIZACION}/:id`} element={<ViewApprovalsGenericDetail />} />
                            </Route>
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA}`}>
                                <Route path={''} element={<ViewCajaChicaMain />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_RENDICION_GASTOS}/form`} element={<ViewDetailRendicionGastoForm />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_RENDICION_GASTOS}/:id`} element={<ViewDetailRendicionGastoForm />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_RENDICION_GASTOS}`} element={<ViewCajaChicaGenericList />} />

                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_SOLICITUD_ANTICIPO}/form`} element={<ViewDetailAnticipo />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_SOLICITUD_ANTICIPO}/:id`} element={<ViewDetailAnticipo />} />
                                <Route path={`${process.env.REACT_APP_ROUTE_PATH_CAJACHICA_RENDIDOR_SOLICITUD_ANTICIPO}`} element={<ViewCajaChicaGenericList />} />
                            </Route>
                        </Route>
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_DOCUCOBRA}`}>
                            <Route path={``} element={<ViewDocucobraListSecondVersion />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_DOCUCOBRA_LISTAR}`} element={<ViewDocucobraListSecondVersion />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_DOCUCOBRA_LISTAR}/:id`} element={<ViewDocucobraListSecondVersion />} />
                        </Route>
                        <Route path={`${process.env.REACT_APP_ROUTE_PATH_TAREO}`}>
                            <Route path={``} element={<ViewTareoMain />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_TAREO_PERSONAL}/form`} element={<ViewTareoFormPersonal />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_TAREO_PERSONAL}/:id`} element={<ViewTareoFormPersonal />} />
                            <Route path={`${process.env.REACT_APP_ROUTE_PATH_TAREO_PERSONAL}`} element={<ViewTareoGenericList />} />
                        </Route>
                    </Route>
                </RoutesWithNotFound>
            </BrowserRouter>
        </Suspense>
    )
}