import React from 'react';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { EntityMain } from '../../Domain/EntityMain';
import { EntityModeMultipleSelect } from '../../Domain/EntityModeMultipleSelect';
import { EntityValorizacion } from '../../../../shared/Domain/Catalogos/EntityValorizacion';

interface ContainerProps {
    data: EntityMain<EntityValorizacion>[];
    onRedirectForm(id: string): any;
    show: boolean;
    copyCodeItem: (code: string) => Promise<void>;
    onSelectItem: (code: string) => void;
    modeMultipleSelect: EntityModeMultipleSelect;
}

const languageTranslate = LanguageTranslate();

export const ViewValorization = (props: ContainerProps) => {
    if (!props.show) return null;
    console.log(props.data);
    return (
        <div className="row pt-3 mb-5">
            <div className="col-12 mb-5">
                {
                    props.data.map(row => {
                        if (props.modeMultipleSelect.active && row.isPendingSend) return null;

                        return (
                            <div className="row pb-3 ItemGenericList" key={row._id}>
                                <div className="col-12">
                                    <div className={`shadow-lg position-relative ${row.isPendingSend ? 'pending' : ''}`} style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }}>
                                        <div className={`${props.modeMultipleSelect.active ? 'ModeSelectItem' : ''} ${props.modeMultipleSelect.listCode.includes(row.Codigo) ? 'ModeSelectItem-select' : ''}`} onClick={() => props.onSelectItem(row.Codigo)} />
                                        <div className="row" style={{ margin: '1px' }}>
                                            <div className="col-8" onClick={(evt) => props.copyCodeItem(row.Codigo)}>
                                                <span className='fw-semibold' style={{ fontSize: '14px' }}> {row.Codigo} </span>
                                                <button className='icon-copy-custom'><i className="fa-solid fa-copy" /></button>
                                            </div>
                                            <div className="col-4">
                                                <span className='fw-semibold' style={{ fontSize: '14px' }}> {row.dataComplete.DatosTrabajo?.OT?.Codigo} </span>
                                            </div>
                                        </div>
                                        <div className="row pt-1" style={{ margin: '1px' }} onClick={() => props.onRedirectForm(row._id)} >
                                            <div className="col-10" style={{ fontSize: 12 }}>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextProveedor}:</span>
                                                    <span className='text-muted'> {row.Proveedor} </span>
                                                </div>
                                                <div className='d-flex LabelImporte'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextImporte}:</span>
                                                    <span className='text-muted'> {AdapterGenerico.formatoDinero(row.Importe)} ({row.TipoMoneda}) </span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextFechaRegistro}:</span>
                                                    <span className='text-muted'> {AdapterGenerico.convertDateToString(row.FechaRegistro, 3)} </span>
                                                </div>
                                            </div>
                                            <div className="col-2 text-center d-flex align-items-center" onClick={() => props.onRedirectForm('')}>
                                                <i className="fa-solid fa-angle-right" style={{ fontSize: '20px' }} />
                                            </div>
                                        </div>
                                        <div className="row pt-1" style={{ margin: '1px' }} onClick={() => props.onRedirectForm(row._id)} >
                                            <div className="col-6">
                                                <span className='text-danger' style={{ fontSize: '13px' }}> {AdapterGenerico.calculoTiempo(row.FechaRegistro)} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}