const SCHEMA: string = process.env.REACT_APP_MAESTRO_SEDE_SCHEMA || '';
const ENTITY: string = process.env.REACT_APP_MAESTRO_SEDE_ENTITY || '';
const ROUTE_PREVIEW_SYSTEM_REQUIREMENT: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_SISTEMA}`;
const ROUTE_PREVIEW_PURCHARSE_REQUIREMENT: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_COMPRA}`;
const ROUTE_PREVIEW_COMPARATIVE: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_COMPARATIVO}`;
const ROUTE_PREVIEW_INVOICE: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_FACTURA}`;
const ROUTE_PREVIEW_PETTY_CASH_REQUEST: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_SOLICITUD_CAJA_CHICA}`;
const ROUTE_PREVIEW_SURRENDER_PETTY_CASH_EXPENSES: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_RENDICION_GASTOS_CAJA_CHICA}`;
const ROUTE_PREVIEW_REQUEST_FORM: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_HOJA_PETICION}`;
const ROUTE_PREVIEW_WORKING_CONDITIONS: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_CONDICION_LABORALES}`;
const ROUTE_PREVIEW_TAREO: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_TAREO}`;
const ROUTE_PREVIEW_AUSENCIA: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_AUSENCIA}`;
const ROUTE_PREVIEW_DOCUCOBRA: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_DOCUCOBRA}`;
const ROUTE_REVIEW_VALORIZATION: string = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES}/${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_VALORIZACION}`;

export const AdapterConfigure = {
    SCHEMA,
    ENTITY,
    ROUTE_PREVIEW_SYSTEM_REQUIREMENT,
    ROUTE_PREVIEW_PURCHARSE_REQUIREMENT,
    ROUTE_PREVIEW_COMPARATIVE,
    ROUTE_PREVIEW_INVOICE,
    ROUTE_PREVIEW_PETTY_CASH_REQUEST,
    ROUTE_PREVIEW_SURRENDER_PETTY_CASH_EXPENSES,
    ROUTE_PREVIEW_REQUEST_FORM,
    ROUTE_PREVIEW_WORKING_CONDITIONS,
    ROUTE_PREVIEW_TAREO,
    ROUTE_PREVIEW_AUSENCIA,
    ROUTE_PREVIEW_DOCUCOBRA,
    ROUTE_REVIEW_VALORIZATION
};