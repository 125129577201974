import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { EntityComparative } from "../../../shared/Domain/EntityComparative";
import { EntityFactura } from "../../../shared/Domain/EntityFactura";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { addLoading, changeSaludo, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { UseCaseSave } from "../Application/UseCaseSave";
import { EntityListContent, EntityListContentInitial } from "../Domain/EntityListContent";
import { EntityRequestAddNote, EntityRequestDetail, NameStore } from "../Domain/EntityRequestDetail";
import { EntityRequestDownloadFile } from "../Domain/EntityRequestDownloadFile";
import { EntityTypeModule } from "../Domain/EntityTypeModule";
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { UseCaseGetDetailItem } from "../Application/UseCaseGetDetailItem";
import { EntitySystemRequirement } from "../../../shared/Domain/EntitySystemRequirement";
import { EntityPurchaseRequirement } from "../../../shared/Domain/EntityPurchaseRequirement";
import { EntityPettyCashRequest } from "../../../shared/Domain/EntityPettyCashRequest";
import { EntitySurrenderPettyCashExpenses } from "../../../shared/Domain/EntitySurrenderPettyCashExpenses";
import { EntityOptionsPDF, initEntityOptionsPDF } from "../Domain/EntityOptionsPDF";
import { EntityConfigModal, initEntityConfigModal, keyModal } from "../Domain/EntityUtils";
import { UseCaseAddNote } from "../Application/UseCaseAddNote";
import { AdapterCopyText } from "../../../shared/Infraestructure/AdapterCopyText";
import { EntityValorizacion } from "../../../shared/Domain/Catalogos/EntityValorizacion";

let dataComplete: EntityComparative | EntityFactura | EntitySystemRequirement | EntityPurchaseRequirement | EntityPettyCashRequest | EntitySurrenderPettyCashExpenses | EntityValorizacion | null = null;
let dataCompleteFormatted: EntityListContent<any> | null = null;
let nameStore: NameStore;

export const Controller = () => {
    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const [listContent, setListContent] = useState<EntityListContent<any>>(EntityListContentInitial)
    const [modeTypeModule, setModeTypeModule] = useState<EntityTypeModule>({ key: '', navBar: [] });
    const [viewOptionsPDF, setViewOptionsPDF] = useState<EntityOptionsPDF>(initEntityOptionsPDF);
    const [configModal, setConfigModal] = useState<EntityConfigModal>(initEntityConfigModal);

    const languageTranslate = LanguageTranslate();
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const init = () => {
        dispatch(changeSaludo(false));
        configModeType();
    }

    const configModeType = () => {
        const arrPathname = window.location.pathname.split('/');
        const validationPathName = (module: string) => arrPathname.some(item => item === module);
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_COMPARATIVO}`)) initModeComparative();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_FACTURA}`)) initModeInvoice();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_SISTEMA}`)) initModeSystemRequirement();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_REQUERIMIENTO_COMPRA}`)) initModePurcharseRequirement();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_SOLICITUD_CAJA_CHICA}`)) initModePettyCashRequest();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_RENDICION_GASTOS_CAJA_CHICA}`)) initModeSurrenderPettyCashExpenses();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_HOJA_PETICION}`)) initModeRequestForm();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_CONDICION_LABORALES}`)) initModeWorkinContidions();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_AUSENCIA}`)) initModeAusencia();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_DOCUCOBRA}`)) initModeDocucobra();
        if (validationPathName(`${process.env.REACT_APP_ROUTE_PATH_APROBACIONES_VALORIZACION}`)) initModeValorization();
    }

    // --------------------------------- Iniciar formulario
    const initModeComparative = async () => {
        setModeTypeModule({ key: 'comparative', navBar: languageTranslate.moduloGenericDetail.navBarComparativo });
        nameStore = 'Comparativo';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.comparativoErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_COMPARATIVE));
            return;
        }
        setListContent(prev => (dataFormatted));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        autoDownloadFilePrincipal();
    }

    const initModeInvoice = async () => {
        setModeTypeModule({ key: 'invoice', navBar: languageTranslate.moduloGenericDetail.navBarFactura });
        nameStore = 'Factura';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted || !data) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.facturaErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_INVOICE));
            return;
        }
        setListContent(prev => (dataFormatted));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        autoDownloadFilePrincipal();
    }

    const initModeSystemRequirement = async () => {
        setModeTypeModule({ key: 'system-requirement', navBar: languageTranslate.moduloGenericDetail.navBarRequerimientoSistemas });
        nameStore = 'RequerimientoSistema';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.requerimientoSistemaErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_SYSTEM_REQUIREMENT));
            return;
        }
        setListContent(prev => (dataFormatted));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        autoDownloadFilePrincipal();
    }

    const initModePurcharseRequirement = async () => {
        setModeTypeModule({ key: 'purchase-requirement', navBar: languageTranslate.moduloGenericDetail.navBarRequerimientoCompra });
        nameStore = 'RequerimientoCompra';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.requerimientoCompraErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_PURCHARSE_REQUIREMENT));
            return;
        }
        setListContent(prev => (dataFormatted));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        autoDownloadFilePrincipal();
    }

    const initModePettyCashRequest = async () => {
        setModeTypeModule({ key: 'petty-cash-request', navBar: languageTranslate.moduloGenericDetail.navBarSolicitudCajaChica });
        nameStore = 'SolicitudCajaChica';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.solicitudCajaChicaErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_PURCHARSE_REQUIREMENT));
            return;
        }
        setListContent(prev => (dataFormatted));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        autoDownloadFilePrincipal();
    }

    const initModeSurrenderPettyCashExpenses = async () => {
        setModeTypeModule({ key: 'surrender-petty-cash-expenses', navBar: languageTranslate.moduloGenericDetail.navBarRendicionGastosCajaChica });
        nameStore = 'RendicionGastosCajaChica';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.rendicionGastoCajaChicaErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_PURCHARSE_REQUIREMENT));
            return;
        }
        setListContent(prev => (dataFormatted));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        autoDownloadFilePrincipal();
    }

    const initModeRequestForm = async () => {
        setModeTypeModule({ key: 'request-form', navBar: languageTranslate.moduloGenericDetail.navBarHojaPeticion });
        nameStore = 'HojaPeticion';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.hojaPeticionErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_REQUEST_FORM));
            return;
        }
        setListContent(prev => (dataFormatted));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        autoDownloadFilePrincipal();
    }

    const initModeWorkinContidions = async () => {
        setModeTypeModule({ key: 'working-conditions', navBar: languageTranslate.moduloGenericDetail.navBarWorkingConditions });
        nameStore = 'CondicionLaboral';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.condicionLaboralErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_WORKING_CONDITIONS));
            return;
        }
        setListContent(prev => (dataFormatted));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        autoDownloadFilePrincipal();
    }

    const initModeAusencia = async () => {
        setModeTypeModule({ key: 'ausencia', navBar: languageTranslate.moduloGenericDetail.navBarAusencia });
        nameStore = 'AusenciaAprobacion';
        const { dataFormatted, data } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.condicionLaboralErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_AUSENCIA));
            return;
        }
        setListContent(prev => ({...dataFormatted, dataComplete: data}));

        // información de uso inicial
        dataComplete = data;
        dataCompleteFormatted = dataFormatted;
        // autoDownloadFilePrincipal();
    }

    const initModeDocucobra = async () => {
        setModeTypeModule({ key: 'docucobra', navBar: languageTranslate.moduloGenericDetail.navBarDocucobra });
        nameStore = 'DocucobraAprobacion';
        const { dataFormatted } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.docucobraErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_DOCUCOBRA));
            return;
        }
        setListContent(prev => ({...dataFormatted}));

        // información de uso inicial
        dataComplete = dataFormatted.dataComplete;
        dataCompleteFormatted = dataFormatted;
        // autoDownloadFilePrincipal();
    }

    const initModeValorization = async () => {
        setModeTypeModule({ key: 'valorization', navBar: languageTranslate.moduloGenericDetail.navBarValorization });
        nameStore = 'ValorizacionAprobacion';
        const { dataFormatted } = await new UseCaseGetDetailItem(repository).exec(`${params.id}`, nameStore);
        if (!dataFormatted) {
            AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.valorizationErrorDetalle, 'error').then(res => navigate(AdapterConfigure.ROUTE_LIST_VALORIZATION));
            return;
        }
        setListContent(prev => ({...dataFormatted}));

        // información de uso inicial
        dataComplete = dataFormatted.dataComplete;
        dataCompleteFormatted = dataFormatted;
        // autoDownloadFilePrincipal();
    }

    // ---------------------------------

    const autoDownloadFilePrincipal = async () => {
        // proceso de auto descarga con el archivo principal
        const hasFilePrincipal = dataCompleteFormatted?.documento.find(row => row.autoDownload);
        const isDownloaded = dataCompleteFormatted?.listFileDownloaded.some(row => row.code === hasFilePrincipal?.code);
        // si no esta descargado el archivo, procede a descargar en segundo plano
        if (hasFilePrincipal && !isDownloaded) {
            try {
                const responseFile = await repository.downloadFile(hasFilePrincipal.path);
                if (!responseFile) return null;
                saveFilePrincipal(responseFile, hasFilePrincipal.code)
            } catch (error) { }
        }
    }

    const confirmDetail = async (type: 'approve' | 'return') => {
        if (!dataCompleteFormatted?.detail.isFacturaAsociada && modeTypeModule.key === 'invoice' && type === 'approve') return AdapterGenerico.createMessage('Error', languageTranslate.moduloGenericDetail.errors.facturaNoAsociada, 'error', false)

        let moduleText: string = '';
        switch(modeTypeModule.key) {
            case 'comparative': moduleText = languageTranslate.moduloApprovals.question.textoComparativo; break;
            case 'invoice': moduleText = languageTranslate.moduloApprovals.question.textoFactura; break;
            case 'system-requirement': moduleText = languageTranslate.moduloApprovals.question.textoRequerimientoSistema; break;
            case 'purchase-requirement': moduleText = languageTranslate.moduloApprovals.question.textoRequerimientoCompra; break;
            case 'petty-cash-request': moduleText = languageTranslate.moduloApprovals.question.textoSolicitudCajaChica; break;
            case 'surrender-petty-cash-expenses': moduleText = languageTranslate.moduloApprovals.question.textoRendicionGastoCajaChica; break;
            case 'request-form': moduleText = languageTranslate.moduloApprovals.question.textoHojaPeticion; break;
            case 'working-conditions': moduleText = languageTranslate.moduloApprovals.question.textoCondicionLaboral; break;
            case 'valorization': moduleText = languageTranslate.moduloApprovals.question.textoValorization; break;
        };

        const message = `${languageTranslate.moduloApprovals.question.textoDesea} ${type === 'approve' ? languageTranslate.moduloApprovals.question.textoAprobar : languageTranslate.moduloApprovals.question.textoDevolver} ${moduleText}`;
        const isAccept = await AdapterGenerico.createMessage('', message, 'info', true);
        if (!isAccept || !dataComplete?.Codigo) return;
        const updateDate = (dataComplete.Dates as any)?.Update?.Date;
        const bitacora = dataComplete.Bitacora || [];
        let paramDate = !!updateDate ? updateDate : (bitacora.length === 0 ? '' : bitacora[(bitacora.length - 1)].Date.Date);

        const payload: EntityRequestDetail = {
            paramsSend: {
                ...(modeTypeModule.key === 'valorization' ? { codigovalorizacion: dataComplete._id, comentario: "" } : {}),
                codigo: dataComplete.Codigo,
                identificacion: user.Identificacion,
                fecha: paramDate
            },
            extraConfig: {
                dataComplete: dataComplete,
                moduleKey: modeTypeModule.key,
                nameStore: nameStore,
                typeSave: type,
                user
            }
        }

        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoEnviando }))
            let response = await new UseCaseSave(repository).exec(payload);
    
            if (response) {
                let redirectList = "";
                switch(modeTypeModule.key) {
                    case 'comparative': redirectList = AdapterConfigure.ROUTE_LIST_COMPARATIVE; break;
                    case 'invoice': redirectList = AdapterConfigure.ROUTE_LIST_INVOICE; break;
                    case 'system-requirement': redirectList = AdapterConfigure.ROUTE_LIST_SYSTEM_REQUIREMENT; break;
                    case 'purchase-requirement': redirectList = AdapterConfigure.ROUTE_LIST_PURCHARSE_REQUIREMENT; break;
                    case 'petty-cash-request': redirectList = AdapterConfigure.ROUTE_LIST_PETTY_CASH_REQUEST; break;
                    case 'surrender-petty-cash-expenses': redirectList = AdapterConfigure.ROUTE_LIST_SURRENDER_PETTY_CASH_EXPENSES; break;
                    case 'request-form': redirectList = AdapterConfigure.ROUTE_LIST_REQUEST_FORM; break;
                    case 'working-conditions': redirectList = AdapterConfigure.ROUTE_LIST_WORKING_CONDITIONS; break;
                    case 'ausencia': redirectList = AdapterConfigure.ROUTE_LIST_AUSENCIA; break;
                    case 'docucobra': redirectList = AdapterConfigure.ROUTE_LIST_DOCUCOBRA; break;
                    case 'valorization': redirectList = AdapterConfigure.ROUTE_LIST_VALORIZATION; break;
                };
                navigate(redirectList);
            }
        }
        catch (error) {
            let msg = (error as Error).message;
            if (msg === 'error-date-update') {
                msg = `No se puede ${type === 'approve' ? languageTranslate.moduloApprovals.question.textoAprobar : languageTranslate.moduloApprovals.question.textoDevolver}, el registro actualmente cuenta con modificaciones`
            }
            msg = `${msg} - ${dataComplete.Codigo}`
            AdapterGenerico.createMessage('Error', msg, 'error', false);
        }
        finally { dispatch(removeLoading()); }
        
    }

    const downloadFile = async (src: string) => {
        let alink = document.createElement('a');
        alink.href = src;
        alink.download = viewOptionsPDF.title;
        alink.click();
    }

    const viewDocument = async (payload: EntityRequestDownloadFile, code: string) => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoDescargando }))
            let responseFile = verifiedFileDownloaded(code);
            if (!responseFile) responseFile = await repository.downloadFile(payload);
            if (!responseFile) return null;
            const fileBase64 = await AdapterGenerico.toBase64(responseFile) as string;
            saveFilePrincipal(responseFile, code)

            setViewOptionsPDF(() => ({
                show: true,
                title: payload.fileName,
                src: fileBase64
            }))
        } catch(error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading())
        }
    }

    const closeViewDocument = () => {
        setViewOptionsPDF(() => ({...initEntityOptionsPDF}))
    }

    const saveFilePrincipal = async (file: File, code: string) => {
        if (!dataCompleteFormatted) return null;
        const findIndex = dataCompleteFormatted.listFileDownloaded.findIndex(row => row.code === code);
        if (findIndex === -1) dataCompleteFormatted.listFileDownloaded.push({ code, file });
        await dbLocal.updateByIndexStore({ nameStore, value: { ...dataComplete, listFileDownloaded: dataCompleteFormatted.listFileDownloaded } });
    }

    const verifiedFileDownloaded = (codeSearch: string): File | null => {
        if (!dataCompleteFormatted) return null;
        return dataCompleteFormatted.listFileDownloaded.find(row => row.code === codeSearch)?.file || null;
    }

    // Config Modal
    const openModal = (key: keyModal, payload?: any) => {

        switch (key) {
            case 'reject':
                setConfigModal(prev => ({
                    ...prev,
                    title: 'Devolver',
                    comment: '',
                    description: 'Por favor confirme esta acción y agregue un comentario explicando por qué está devolviendo este registro.',
                    key,
                    show: true
                }));
            break;
            case 'notes':
                setConfigModal(prev => ({
                    ...prev,
                    title: 'Notas',
                    comment: '',
                    description: '',
                    key,
                    show: true
                }));
            break;
            case 'product':
                setConfigModal(prev => ({
                    ...prev,
                    title: 'Detalle Producto',
                    comment: '',
                    description: '',
                    key,
                    show: true,
                    payload
                }));
            break;
            case 'resume-factura':
                setConfigModal(prev => ({
                    ...prev,
                    title: 'Resumen de la Factura',
                    comment: '',
                    description: '',
                    key,
                    show: true,
                    payload
                }));
            break;
        }
    }

    const closeModal = () => {
        setConfigModal(prev => ({
            ...prev,
            title: '',
            comment: '',
            description: '',
            key: 'reject',
            show: false
        }))
    }

    const onChange = (name: string, value: string) => {
        setConfigModal(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const onSubmitM = async (evt: Event) => {
        evt.preventDefault();
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoEnviando }))

            if (configModal.key === 'reject') {
                if (configModal.comment === '') return AdapterGenerico.createMessage('Incompleto', 'Ingrese un comentario', 'warning', false);

                const updateDate = (dataComplete?.Dates as any)?.Update?.Date;
                const bitacora = dataComplete?.Bitacora || [];
                let paramDate = !!updateDate ? updateDate : (bitacora.length === 0 ? '' : bitacora[(bitacora.length - 1)].Date.Date);

                const payload: EntityRequestDetail = {
                    paramsSend: {
                        ...(modeTypeModule.key === 'valorization' ? { codigovalorizacion: dataComplete!._id } : {}),
                        codigo: dataComplete?.Codigo || '',
                        identificacion: user.Identificacion,
                        fecha: paramDate,
                        comentario: configModal.comment
                    },
                    extraConfig: {
                        dataComplete: dataComplete,
                        moduleKey: modeTypeModule.key,
                        nameStore: nameStore,
                        typeSave: 'return',
                        user: user
                    }
                }

                let response = await new UseCaseSave(repository).exec(payload);
        
                if (response) {
                    let redirectList = "";
                    switch(modeTypeModule.key) {
                        case 'comparative': redirectList = AdapterConfigure.ROUTE_LIST_COMPARATIVE; break;
                        case 'invoice': redirectList = AdapterConfigure.ROUTE_LIST_INVOICE; break;
                        case 'system-requirement': redirectList = AdapterConfigure.ROUTE_LIST_SYSTEM_REQUIREMENT; break;
                        case 'purchase-requirement': redirectList = AdapterConfigure.ROUTE_LIST_PURCHARSE_REQUIREMENT; break;
                        case 'petty-cash-request': redirectList = AdapterConfigure.ROUTE_LIST_PETTY_CASH_REQUEST; break;
                        case 'surrender-petty-cash-expenses': redirectList = AdapterConfigure.ROUTE_LIST_SURRENDER_PETTY_CASH_EXPENSES; break;
                        case 'request-form': redirectList = AdapterConfigure.ROUTE_LIST_REQUEST_FORM; break;
                        case 'working-conditions': redirectList = AdapterConfigure.ROUTE_LIST_WORKING_CONDITIONS; break;
                        case 'ausencia': redirectList = AdapterConfigure.ROUTE_LIST_AUSENCIA; break;
                        case 'docucobra': redirectList = AdapterConfigure.ROUTE_LIST_DOCUCOBRA; break;
                        case 'valorization': redirectList = AdapterConfigure.ROUTE_LIST_VALORIZATION; break;
                    };
                    navigate(redirectList);
                }
            }

            if (configModal.key === 'notes') {
                if (configModal.comment === '') return AdapterGenerico.createMessage('Incompleto', 'Ingrese un comentario', 'warning', false);

                const payload: EntityRequestAddNote = {
                    paramsSend: {
                        codigo: dataComplete?.Codigo || '',
                        nota: configModal.comment
                    },
                    extraConfig: {
                        moduleKey: modeTypeModule.key,
                        nameStore: nameStore,
                        typeSave: 'return'
                    }
                }

                let response = await new UseCaseAddNote(repository).exec(payload);

                if (response && dataComplete) {
                    dataComplete.Notas = [
                        ...(dataComplete?.Notas || []),
                        response.data,
                    ];
                    (dataComplete.Dates as any) = { ...(dataComplete.Dates as any || {}), Update: response.data.Date };
                    let templateNewNote = {
                        observacion: response.data.Description,
                        fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(response.data.Date.Date), 3),
                        usuario: `${response.data.User.Name} ${response.data.User.LastName}`,
                        action: "NUEVA NOTA"
                    }
                    setListContent(prev => ({
                        ...prev,
                        notas: [
                            templateNewNote,
                            ...prev.notas,
                        ]
                    }));
                    await dbLocal.updateByIndexStore({ nameStore, value: dataComplete })
                }
            }
        } catch(error) {
            let msg = (error as Error).message;
            if (msg === 'error-date-update') {
                msg = `No se puede ${languageTranslate.moduloApprovals.question.textoDevolver}, el registro actualmente cuenta con modificaciones`
            }
            msg = `${msg} - ${dataComplete?.Codigo}`
            AdapterGenerico.createMessage('Error', msg, 'error', false);
        } finally {
            dispatch(removeLoading());
        }

        closeModal();
    }

    const copyCodeItem = async (code: string) => {
        await AdapterCopyText.copyText(code);
        AdapterGenerico.createToast(`${languageTranslate.textoCopiar}`, 'success');
    }

    return {
        init,
        listContent,
        modeTypeModule,
        viewOptionsPDF,
        confirmDetail,
        viewDocument,
        closeViewDocument,
        downloadFile,

        // Config Modal
        openModal,
        closeModal,
        onChange,
        onSubmitM,
        configModal,

        copyCodeItem,
    }
}