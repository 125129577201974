// Components
import { ViewComparativo } from './module/ViewComparativo';
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import ElementStepperHeader from '../../../shared/Components/ElementStepperHeader';

// Infraestructure
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';

// Domain
import { keyModal } from '../Domain/EntityUtils';
import { EntityTypeModule } from '../Domain/EntityTypeModule';
import { EntityListContent } from '../Domain/EntityListContent';
import { EntityRequestDownloadFile } from '../Domain/EntityRequestDownloadFile';

// Module
import { ViewFactura } from './module/ViewFactura';
import { ViewAusencia } from './module/ViewAusencia';
import { ViewDocucobra } from './module/ViewDocucobra';
import { ViewHojaPeticion } from './module/ViewHojaPeticion';
import { ViewCondicionLaboral } from './module/ViewCondicionLaboral';
import { ViewSolicitudCajaChica } from './module/ViewSolicitudCajaChica';
import { ViewRequerimientoCompra } from './module/ViewRequerimientoCompra';
import { ViewRequerimientoSistema } from './module/ViewRequerimientoSistema';
import { ViewRendicionGastosCajaChica } from './module/ViewRendicionGastosCajaChica';

// Assets
import './Style.scss';
import { ViewValorization } from './module/ViewValorization';

interface PropsViewMain {
    confirmDetail: (type: 'approve' | 'return') => Promise<any>;
    listContent: EntityListContent<any>;
    modeTypeModule: EntityTypeModule;
    copyCodeItem: Function;
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
    openModal: (key: keyModal) => void
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className='ViewComparativeDetail'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={props.modeTypeModule.navBar} />
                <ElementStepperHeader
                    data={props.listContent.stepperUser}
                    onChange={() => {}}
                    stepPosition={props.listContent.currentStepUser}
                    stepNumberStart={props.listContent.stepNumberStartUser}
                />

                <ViewComparativo copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'comparative'} openModal={props.openModal} />
                <ViewFactura copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'invoice'} openModal={props.openModal} />
                <ViewRequerimientoSistema copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'system-requirement'} openModal={props.openModal} />
                <ViewRequerimientoCompra copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'purchase-requirement'} openModal={props.openModal} />
                <ViewSolicitudCajaChica copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'petty-cash-request'} openModal={props.openModal} />
                <ViewRendicionGastosCajaChica copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'surrender-petty-cash-expenses'} openModal={props.openModal} />
                <ViewHojaPeticion copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'request-form'} />
                <ViewCondicionLaboral copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'working-conditions'} />
                <ViewAusencia viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'ausencia'} />
                <ViewDocucobra viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'docucobra'} />
                <ViewValorization copyCodeItem={props.copyCodeItem} viewDocument={props.viewDocument} listContent={props.listContent} show={props.modeTypeModule.key === 'valorization'} openModal={props.openModal} />

            </div>
            <div className='container_group_button shadow-lg'>
                <div className='group_button'>
                    <button className='btn btn-danger' onClick={() => props.openModal('reject')} disabled={props.listContent.alreadyAcceptItem}>
                        <i className="fa-solid fa-arrow-rotate-left" style={{ fontSize: 14, marginRight: 12 }} />
                        {languageTranslate.moduloGenericDetail.btnTextDevolver}
                    </button>
                    <button className='btn btn-success' onClick={() => props.confirmDetail('approve')} disabled={props.listContent.alreadyAcceptItem}>
                        <i className="fa-solid fa-check" style={{ fontSize: 14, marginRight: 12 }} />
                        {languageTranslate.moduloGenericDetail.btnTextAprobar}
                    </button>
                </div>
            </div>
        </div >
    )
};