import { EntityValorizacion } from "../../../shared/Domain/Catalogos/EntityValorizacion";
import { EntityComparative } from "../../../shared/Domain/EntityComparative";
import { EntityDocucobra } from "../../../shared/Domain/EntityDocucobra";
import { EntityFactura } from "../../../shared/Domain/EntityFactura";
import { EntityPettyCashRequest } from "../../../shared/Domain/EntityPettyCashRequest";
import { EntityPurchaseRequirement } from "../../../shared/Domain/EntityPurchaseRequirement";
import { EntityRequestForm } from "../../../shared/Domain/EntityRequestForm";
import { EntitySurrenderPettyCashExpenses } from "../../../shared/Domain/EntitySurrenderPettyCashExpenses";
import { EntitySystemRequirement } from "../../../shared/Domain/EntitySystemRequirement";
import { EntityWorkingCondition } from "../../../shared/Domain/EntityWorkingCondition";
import { EntityAusenciaAprobacion } from "../../../shared/Domain/Tareo/EntityAusenciaAprobacion";
import { EntityTareoAprobacion } from "../../../shared/Domain/Tareo/EntityTareoAprobacion";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { addCountProcess } from "../../../shared/Infraestructure/SliceGenerico";
import { DtoRequestList } from "../Domain/DtoRequestList";
import { EntityMain } from "../Domain/EntityMain";
import { EntityRequestDetail } from "../Domain/EntityRequestDetail";
import { KeyTypeModule } from "../Domain/EntityTypeModule";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {
    public async save(params: EntityRequestDetail): Promise<boolean | null> {
        try {
            let response = await this._save(params);
            let rowLocal = await this._saveLocal(params);

            if (response) await this.dbLocal.deleteByIndexStore({ nameStore: params.extraConfig.nameStore, value: rowLocal._id });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    };

    private async _save(payload: EntityRequestDetail): Promise<boolean | null> {
        if (!navigator.onLine) { this.dispatch(addCountProcess()); return null; }

        if (payload.extraConfig.moduleKey === 'comparative' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarPreOrdenWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'comparative' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarPreOrdenWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'invoice' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarPreRegistroWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'invoice' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarPreRegistroWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }
        
        if (payload.extraConfig.moduleKey === 'system-requirement' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarRqSistemaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'system-requirement' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarRqSistemaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'purchase-requirement' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarRqCompraWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'purchase-requirement' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarRqCompraWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'petty-cash-request' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarSolicitudCajaChicaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'petty-cash-request' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarSolicitudCajaChicaWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'surrender-petty-cash-expenses' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarRendicionCajaChicaReembolsoWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'surrender-petty-cash-expenses' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarRendicionCajaChicaReembolsoWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'request-form' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarHojaPeticionWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'request-form' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarHojaPeticionWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'working-conditions' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarCondicionLaboralWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'working-conditions' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarCondicionLaboralWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'tareo' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/workflow/aprobar-tareo`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'tareo' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/workflow/retornar-tareo`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'ausencia' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/workflow/ausencia/aprobar`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'ausencia' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/workflow/ausencia/retornar`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'docucobra' && payload.extraConfig.typeSave === 'approve') {
            const _param = {
                Codigo: payload.paramsSend.codigo,
                CodigoCategoria: payload.extraConfig.dataComplete.Categoria.Codigo,
                Identificacion: payload.paramsSend.identificacion,
                User: {
                    IdUser: payload.extraConfig.user.IdUser,
                    User: payload.extraConfig.user.User,
                    LastFather: payload.extraConfig.user.LastNameFather,
                    LastMother: payload.extraConfig.user.LastNameMother,
                    Name: payload.extraConfig.user.Name,
                    Perfil: payload.extraConfig.user.Profile[0]?.Perfil || ''
                } 
            }

            let url: string = `${this.urlBase}/v2/DocuCobra/Documentos/aprobar`;
            return await this.service.call<any>("POST", url, JSON.stringify(_param), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);
        }

        if (payload.extraConfig.moduleKey === 'docucobra' && payload.extraConfig.typeSave === 'return') {
            const _param = {
                Codigo: payload.paramsSend.codigo,
                CodigoCategoria: payload.extraConfig.dataComplete.Categoria.Codigo,
                Identificacion: payload.paramsSend.identificacion,
                Comentario: payload.paramsSend.comentario || '',
                User: {
                    IdUser: payload.extraConfig.user.IdUser,
                    User: payload.extraConfig.user.User,
                    LastFather: payload.extraConfig.user.LastNameFather,
                    LastMother: payload.extraConfig.user.LastNameMother,
                    Name: payload.extraConfig.user.Name,
                    Perfil: payload.extraConfig.user.Profile[0]?.Perfil || ''
                } 
            }
            let url: string = `${this.urlBase}/v2/DocuCobra/Documentos/devolver`;
            return await this.service.call<any>("POST", url, JSON.stringify(_param), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);
        }

        if (payload.extraConfig.moduleKey === 'valorization' && payload.extraConfig.typeSave === 'approve') {
            let url: string = `${this.urlBase}/navision/aprobarValorizacionesWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        if (payload.extraConfig.moduleKey === 'valorization' && payload.extraConfig.typeSave === 'return') {
            let url: string = `${this.urlBase}/navision/retornarValorizacionesWorkFlow`;
            return await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0);
        }

        return false;
    }

    private async _saveLocal(payload: EntityRequestDetail): Promise<any> {
        let response = { ...payload.extraConfig.dataComplete, pendingSend: true, dataSend: payload };
        await this.dbLocal.updateByIndexStore({ nameStore: payload.extraConfig.nameStore, value: response });
        return response;
    }

    private selectLastDate = (dates: any[]) => {
        if (dates.length === 0) return new Date();
        let maxDate: Date | null = null;
        dates.forEach(row => {
            if (!row) return;
            let currentDate = AdapterGenerico.convertStringToDate(row);
            if(maxDate === null || Number(currentDate) > Number(maxDate)) maxDate = currentDate;
        })
        if (!maxDate) return new Date();
        return maxDate;
    }

    public formatValorizationToGenericList(payload: EntityValorizacion[]): EntityMain<EntityValorizacion>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.CodeValorizacion,
            OT: row.DataValorizacion.DatosTrabajo.OT.Codigo,
            Proveedor: row.DatosEmpresa.Empresa,
            TipoMoneda: row.DataValorizacion.Moneda,
            Importe: row.DataValorizacion.Importe ?? 0,
            FaltaAsociacion: false,
            Sociedad: '',
            FechaRecibido: new Date(),
            FechaRegistro: (row.Dates.Update?.Date || row.Dates.Registry.Date)? AdapterGenerico.convertStringToDate(row.Dates.Update?.Date || row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatDocucobraToGenericList(payload: EntityDocucobra[]): EntityMain<EntityDocucobra>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: '',
            Proveedor: '',
            TipoMoneda: '',
            Importe: 0,
            FaltaAsociacion: false,
            Sociedad: '',
            FechaRecibido: new Date(),
            FechaRegistro: (row.Dates.Update?.Date || row.Dates.Registry.Date)? AdapterGenerico.convertStringToDate(row.Dates.Update?.Date || row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatAusenciaToGenericList(payload: EntityAusenciaAprobacion[]): EntityMain<EntityAusenciaAprobacion>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: '',
            Proveedor: '',
            TipoMoneda: '',
            Importe: 0,
            FaltaAsociacion: false,
            Sociedad: '',
            FechaRecibido: new Date(),
            FechaRegistro: (row.Dates.Update?.Date || row.Dates.Registry.Date)? AdapterGenerico.convertStringToDate(row.Dates.Update?.Date || row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatTareoToGenericList(payload: EntityTareoAprobacion[]): EntityMain<EntityTareoAprobacion>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: '',
            TipoMoneda: '',
            Importe: 0,
            FaltaAsociacion: false,
            Sociedad: '',
            FechaRecibido: new Date(),
            FechaRegistro: row.Dates.Update.Fecha ? AdapterGenerico.convertStringToDate(row.Dates.Update.Fecha) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatWorkingConditionToGenericList(payload: EntityWorkingCondition[]): EntityMain<EntityWorkingCondition>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: `${row.DatosSolicitante.Nombres} ${row.DatosSolicitante.ApellidoPaterno} ${row.DatosSolicitante.ApellidoMaterno}`,
            TipoMoneda: '',
            Importe: 0,
            FaltaAsociacion: false,
            Sociedad: '',
            FechaRecibido: new Date(),
            FechaRegistro: row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatRequestFormToGenericList(payload: EntityRequestForm[]): EntityMain<EntityRequestForm>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: `${row.DatosSolicitante.Nombres} ${row.DatosSolicitante.ApellidoPaterno} ${row.DatosSolicitante.ApellidoMaterno}`,
            TipoMoneda: '',
            Importe: 0,
            FaltaAsociacion: false,
            Sociedad: '',
            FechaRecibido: new Date(),
            FechaRegistro: row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatSurrenderPettyCashExpensesToGenericList(payload: EntitySurrenderPettyCashExpenses[]): EntityMain<EntitySurrenderPettyCashExpenses>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: row.DatosPersonal.FullName,
            TipoMoneda: row.DatosImporte.Moneda.Moneda,
            Importe: row.DatosImporte.Solicitud.ImporteTotal ?? 0,
            FaltaAsociacion: false,
            Sociedad: '',
            FechaRecibido: this.selectLastDate([ row.Dates.Closed.Date, row.Dates.PreAccepted?.Date, row.Dates.Canceled?.Date ]),
            FechaRegistro: row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatPettyCashRequestToGenericList(payload: EntityPettyCashRequest[]): EntityMain<EntityPettyCashRequest>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: row.DatosPersonal.FullName ?? `${row.DatosPersonal?.Nombres} ${row.DatosPersonal?.ApellidoPaterno} ${row.DatosPersonal?.ApellidoMaterno}`,
            TipoMoneda: row.DatosImporte.Moneda.Moneda,
            Importe: row.DatosImporte.Solicitud.Importe ?? 0,
            FaltaAsociacion: false,
            Sociedad: row.DatosEmpresa.Empresa,
            FechaRecibido: this.selectLastDate([ row.Dates.Closed.Date, row.Dates.PreAccepted?.Date, row.Dates.Canceled?.Date ]),
            FechaRegistro: row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatPurcharseRequirementToGenericList(payload: EntityPurchaseRequirement[]): EntityMain<EntityPurchaseRequirement>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: '',
            TipoMoneda: '',
            Importe: 0,
            FaltaAsociacion: false,
            Sociedad: row.DatosEmpresa.Empresa,
            FechaRecibido: this.selectLastDate([ row.Dates.Closed.Date, row.Dates.PreAccepted?.Date, row.Dates.Canceled?.Date ]),
            FechaRegistro: row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatSystemRequirementToGenericList(payload: EntitySystemRequirement[]): EntityMain<EntitySystemRequirement>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: '',
            TipoMoneda: '',
            Importe: 0,
            FaltaAsociacion: false,
            Sociedad: row.DatosEmpresa.Empresa,
            FechaRecibido: this.selectLastDate([ row.Dates.Closed.Date, row.Dates.PreAccepted?.Date, row.Dates.Canceled?.Date ]),
            FechaRegistro: row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatComparativeToGenericList(payload: EntityComparative[]): EntityMain<EntityComparative>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: row.EmpresaGanadora.Empresa,
            TipoMoneda: row.DatosPago.Moneda.Descripcion,
            Importe: row.DatosPago.Importe.Monto,
            FaltaAsociacion: false,
            Sociedad: '',
            FechaRecibido: this.selectLastDate([ row.Dates.Closed.Date, row.Dates.PreAccepted?.Date, row.Dates.Canceled?.Date ]),
            FechaRegistro: row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
            Global: row.Global,
            Multiproveedor: row.Multiproveedor,
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse();
    }

    public formatInvoiceToGenericList(payload: EntityFactura[]): EntityMain<EntityFactura>[] {
        return payload.map(row => ({
            _id: row._id,
            Codigo: row.Codigo,
            OT: row.DatosTrabajo.OT.Codigo,
            Proveedor: row.DatosPago.Empresa.Name,
            TipoMoneda: row.DatosPago.Moneda.Descripcion,
            Importe: row.DatosPago.Importe.Monto ?? 0,
            FaltaAsociacion: !row.FacturaAsociada,
            Sociedad: '',
            FechaRecibido: this.selectLastDate([ row.Dates.Closed.Date, row.Dates.PreAccepted?.Date, row.Dates.Canceled?.Date ]),
            FechaRegistro: row.Dates.Registry?.Date ? AdapterGenerico.convertStringToDate(row.Dates.Registry.Date) : new Date(),
            isPendingSend: row.pendingSend,
            dataComplete: row
        })).sort((a, b) => Number(a.FechaRegistro) - Number(b.FechaRegistro)).reverse()
    }

    public async getList(params: DtoRequestList, keyModule: KeyTypeModule): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        return this._getList(params, keyModule);
    }

    private async _getList(params: DtoRequestList, keyModule: KeyTypeModule): Promise<{ dataFormatted: EntityMain<any>[], data: any[] } | null> {
        let url: string;
        let result: EntityMain<any>[];

        switch(keyModule) {
            case 'comparative': url = `${this.urlBase}/navision/selectPreOrdenWorkFlowPendiente`; break;
            case 'invoice': url = `${this.urlBase}/navision/selectPreRegistroWorkFlowPendiente`; break;
            case 'purchase-requirement': url = `${this.urlBase}/navision/selectRqCompraWorkFlowPendiente`; break;
            case 'system-requirement': url = `${this.urlBase}/navision/selectRqSistemaWorkFlowPendiente`; break;
            case 'petty-cash-request': url = `${this.urlBase}/navision/selectSolicitudCajaChicaWorkFlowPendiente`; break;
            case 'surrender-petty-cash-expenses': url = `${this.urlBase}/navision/selectRendicionCajaChicaReembolsoWorkFlowPendiente`; break;
            case 'request-form': url = `${this.urlBase}/navision/selectHojaPeticionWorkFlowPendiente`; break;
            case 'working-conditions': url = `${this.urlBase}/navision/selectCondicionLaboralWorkFlowPendiente`; break;
            case 'tareo': url = `${this.urlBase}/navision/workflow/select-tareo-pendiente`; break;
            case 'ausencia': url = `${this.urlBase}/navision/workflow/ausencia/select-pendiente`; break;
            case 'docucobra': url = `${this.urlBase}/v2/DocuCobra/Documentos/aprobacionesPendientes`; break;
            case 'valorization': url = `${this.urlBase}/navision/selectValorizacionesnWorkFlowPendiente`; break;
            default: url = "";
        }

        let response = await this.service.bgCall<any>("POST", url, JSON.stringify(params), "bearer", "json", 'json', { "request-decrypt-response": true }, 0);

        switch(keyModule) {
            case 'comparative': result = this.formatComparativeToGenericList(response); break;
            case 'invoice': result = this.formatInvoiceToGenericList(response); break;
            case 'purchase-requirement': result = this.formatPurcharseRequirementToGenericList(response); break;
            case 'system-requirement': result = this.formatSystemRequirementToGenericList(response); break;
            case 'petty-cash-request': result = this.formatPettyCashRequestToGenericList(response); break;
            case 'surrender-petty-cash-expenses': result = this.formatSurrenderPettyCashExpensesToGenericList(response); break;
            case 'request-form': result = this.formatRequestFormToGenericList(response); break;
            case 'working-conditions': result = this.formatWorkingConditionToGenericList(response); break;
            case 'tareo': result = this.formatTareoToGenericList(response); break;
            case 'ausencia': result = this.formatAusenciaToGenericList(response); break;
            case 'docucobra': result = this.formatDocucobraToGenericList(response); break;
            case 'valorization':
                response = response.map((row: any, index: number) => ({ ...row, _id: `${row.CodeValorizacion}`, _idOriginal: row._id }))
                result = this.formatValorizationToGenericList(response);
                break;
            default: result = [];
        }

        return { data: response, dataFormatted: result };
    }
}